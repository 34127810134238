<template>
  <div>
    <v-card>
      <div class="pa-4 pt-8">
        <v-card-actions class="px-12">
          <v-row align="stretch">
            <v-autocomplete
              v-model="select"
              :search-input.sync="search"
              :items="foundedValues"
              label="Filtrar elementos"
              outlined
              dense
              prepend-icon="mdi-magnify"
            ></v-autocomplete>

            <v-btn
              class="ml-2"
              color="primary"
              fab
              small
              elevation="1"
              @click="createItem()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-text class="mx-6 pa-4 pt-0" v-if="select">
          Tabla filtrada por el valor <b>{{ select }}</b>
          <v-btn
            @click="resetFilter()"
            x-small
            color="accent"
            elevation="1"
            class="ml-2"
          >
            Restablecer filtro
          </v-btn>
        </v-card-text>
        <v-data-table
          id="datatbl"
          :headers="headers"
          :items.sync="tableItems"
          :loading="loading"
          :items-per-page="10"
          class="ma-6 elevation-1 background"
        >
          <template
            v-for="(header, i) in headers"
            v-slot:[`item.${header.value}`]="{ item }"
          >
            <span
              v-if="header.value.toLowerCase().search('priority') > -1"
              :key="i"
            >
              <v-chip :color="getPriorityColor(item[header.value])">
                {{ item[header.value] }}
              </v-chip>
            </span>

            <span v-else-if="header.type === 'boolean'" :key="i">
              <v-icon :color="item[header.value] ? 'success' : 'error'">
                {{ item[header.value] ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </span>
            <span v-else :key="i">
              {{ item[header.value] }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
              class="mr-2"
              @click="editItem(item)"
              :id="`edit-${index}`"
              :disabled="loading"
              v-if="!item.ruleGroupSystem"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              class="mr-2"
              @click="disableItem(item)"
              :id="`disable-${index}`"
              :disabled="loading"
              v-if="!item.ruleGroupSystem"
            >
              {{ item.status ? "mdi-toggle-switch" : "mdi-toggle-switch-off" }}
            </v-icon>
            <v-icon
              @click="showItem(item)"
              :id="`show-${index}`"
              :disabled="loading"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
/**
 * Group Table
 * @author Cristian Molina
 */
import tableUtils from "@/shared/utils/Table";

export default {
  name: "data-table",
  mixins: [tableUtils],
};
</script>
